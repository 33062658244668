<template>
  <base-card>
    <v-card-text>
      <v-row class="align-end">
        <v-col
          cols="12"
          sm="6"
          md="4"
          lg="2"
          class="d-flex justify-md-end justify-sm-center pb-0 pb-sm-3"
        >
          <v-checkbox
            v-model="filterOptions.isCompleted"
            :label="$t('common.completed')"
            hide-details
            class="mt-0 mr-3"
          />
        </v-col>

        <v-col
          cols="12"
          sm="6"
          lg="2"
        >
          <v-text-field
            v-model="filterOptions.accessId"
            hide-details
            clearable
            :label="$t('order.accessId')"
          />
        </v-col>

        <v-col
          cols="12"
          sm="6"
          lg="3"
        >
          <v-text-field
            v-model="filterOptions.email"
            hide-details
            clearable
            :label="$t('common.email')"
          />
        </v-col>

        <v-col
          cols="12"
          sm="6"
          lg="2"
        >
          <v-text-field
            v-model="filterOptions.serviceName"
            hide-details
            clearable
            :label="$t('network.serviceName')"
          />
        </v-col>
      </v-row>

      <v-row class="align-end">
        <v-col
          cols="12"
          class="d-flex justify-center"
        >
          <v-btn
            class="primary px-4 mr-4"
            @click="currentPage = 1; loadOrderList()"
          >
            {{ $t("common.search") }}
          </v-btn>

          <v-btn
            class="error px-4"
            @click="resetFilter">
            {{ $t("common.reset") }}
          </v-btn>
        </v-col>
      </v-row>

      <v-data-table
        :headers="headers"
        :items="orderList"
        :items-per-page="paginationLimit"
        hide-default-footer
        class="eg-table-bnone eg-table-td-py-md mt-2 px-4"
      >
        <template v-slot:item.action="{item}">
          <div class="d-flex justify-space-between">
            <v-btn
              v-if="!item.orderId && !!item.customerInformation.ssn"
              class="ma-1"
              small
              color="primary"
              @click.native="createOrder(item)"
            >
              {{ $t("order.order") }}
            </v-btn>

            <v-btn
              class="ma-1"
              small
              color="info"
              @click.native="viewOrderDetail(item)"
            >
              {{ $t("order.viewDetail") }}
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </v-card-text>

    <v-card-actions class="justify-center">
      <v-pagination
        v-model="currentPage"
        :length="totalPages"
        color="primary"
        :total-visible="7"
        circle
      />
    </v-card-actions>
  </base-card>
</template>

<script>
  import { mapActions } from 'vuex'

  import util from '@/utils'
  import { showSuccessDialog, showErrorDialog } from '@/utils/swalDialog'

  export default {
    metaInfo: {
      title: 'IP-Only Web Orders',
    },

    data () {
      return {
        orderList: [],
        paginationLimit: 10,
        currentPage: 1,
        totalPages: 0,
        headers: [{
          text: this.$t('price.customerName'),
          value: 'customerName',
          width: 160,
        }, {
          text: this.$t('common.phoneNumber'),
          value: 'customerMobilePhone',
          width: 140,
        }, {
          text: this.$t('common.email'),
          value: 'customerEmail',
          width: 160,
        }, {
          text: this.$t('network.serviceName'),
          value: 'netadminServiceName',
          width: 140,
        }, {
          text: this.$t('order.accessId'),
          value: 'accessId',
          width: 120,
        }, {
          text: this.$t('address.address'),
          value: 'fullAddress',
          width: 180,
        }, {
          text: this.$t('order.orderDate'),
          value: 'customerOrderDate',
          width: 160,
        }, {
          text: this.$t('order.desiredDeliveryDate'),
          value: 'desiredDeliveryDate',
          width: 180,
        }, {
          text: this.$t('common.action'),
          value: 'action',
          width: 100,
        }],
        util: util,
        filterOptions: {
          isCompleted: false,
          accessId: '',
          email: '',
          serviceName: '',
        },
      }
    },

    watch: {
      currentPage(val) {
        this.currentPage = val
        this.loadOrderList()
      },
    },

    mounted () {
      this.loadOrderList()
    },

    methods: {
      ...mapActions({
        fetchIpOnlyOrders: 'fetchIpOnlyOrders',
        setLoading: 'setLoading',
        setLoadingText: 'setLoadingText',
        createOrderFromWebOrder: 'createOrderFromWebOrder',
      }),

      async loadOrderList() {
        const query = {
          email: this.filterOptions.email,
          accessId: this.filterOptions.accessId,
          serviceName: this.filterOptions.serviceName,
          isCompleted: this.filterOptions.isCompleted,
          offset: (this.currentPage - 1) * this.paginationLimit,
          limit: this.paginationLimit,
        }

        this.setLoadingText(`${this.$t('common.loading')} ${this.$t('common.data')}...`)
        this.setLoading(true)
        try {
          const response = await this.fetchIpOnlyOrders(query)

          this.totalPages = Math.ceil(response.count / this.paginationLimit)
          this.orderList = response.rows.map(item => {
            const unixOrderDate = Math.floor(item.customerOrderDate / 1000)
            const unixDeliveryDate = Math.floor(item.desiredDeliveryDate / 1000)
            return {
              ...item,
              customerOrderDate: util.formatDateTime(new Date(unixOrderDate * 1000)),
              desiredDeliveryDate: util.formatDateTime(new Date(unixDeliveryDate * 1000)),
            }
          })
        } catch (error) {
          const errorText = util.getErrorResponse(error)
          showErrorDialog(this.$t('common.error'), errorText)
        }
        this.setLoading(false)
      },

      viewOrderDetail(item) {
        this.$router.push(`/ip-only-order/${item.id}`)
      },

      async createOrder(item) {
        this.setLoadingText(`${this.$t('order.creatingOrder')}...`)
        this.setLoading(true)
        try {
          const response = await this.createOrderFromWebOrder({
            webOrderId: item.id,
            type: 'ip-only',
          })
          this.setLoading(false)
          await showSuccessDialog(this.$t('order.networkOrderedSuccessfully'))
          this.$router.push(`/order-price/${response.orderId}`)
        } catch (error) {
          const errorText = util.getErrorResponse(error)
          showErrorDialog(this.$t('common.error'), errorText)
        }

        this.setLoading(false)
      },

      resetFilter() {
        this.filterOptions = {
          email: '',
          accessId: '',
          serviceName: '',
          isCompleted: false,
        }

        if (this.currentPage === 1) {
          this.loadOrderList()
          return
        }

        this.currentPage = 1
      },
    },
  }
</script>
